<script lang="ts" setup>
definePageMeta({
  auth: { unauthenticatedOnly: true, navigateAuthenticatedTo: '/apps' },
  title: 'Tired of focusing on what your customers don\'t care about ? | Featrack',
  uncheckOnboarding: true,
  unPaidAccess: true,
  colorMode: 'light',
})

useHead(
  {
    title: 'Real time feature analytics for your SaaS | Featrack',
  }
)

const isBeta = useRuntimeConfig().public.in_beta
</script>

<template>
  <main class="" data-theme="light">
    <LandingHeader />
    <LandingHeroSection />
    <LandingCompareSection />
    <LandingHowToStartSection />
    <LandingFeaturesSection />
    <LandingPricingConfigurator :to-login="isBeta" />
    <ClientOnly>
      <LandingGetStartedSection mode="newsletter" />
    </ClientOnly>
    <LandingFAQSection />
    <LandingFooter />
  </main>
</template>

<style scoped>
</style>
